<template>
  <div @click="closeLogin" class="login noSel">
    <div @click.stop="stopOn" class="loginBox">
      <div class="loginBox_header">
        <div class="loginBox_header_box flex j_s a_c">
          <!-- <div @click="choseCode" class="onBox" :class="{ on: choseType == 'code' }">扫码登录</div> -->
          <!-- <div class="icon"></div> -->
          <div @click.stop="chosePass" class="onBox">账户登录</div>
        </div>
        <div v-show="choseType == 'code'" class="abs1"></div>
        <div v-show="choseType == 'pass'" class="abs2"></div>
      </div>
      <div v-show="choseType == 'code'">
        <img
          class="loginQr"
          src="https://static.5t1800.com/s2/2021/06/17/60caa3cf8ff4a.png"
          alt
        />
        <div class="loginUseText flex j_c a_c">
          <img
            src="https://static.5t1800.com/s2/2021/06/17/60caa4743030d.png"
            alt
          />
          <span>使用微信扫描二维码登录</span>
        </div>
        <div class="surprised">注册有惊喜</div>
      </div>
      <div v-show="choseType == 'pass'">
        <div class="inputBox flex a_c" style="margin-top: 60px">
          <div v-show="isShowPhoneTip" class="textTip">*手机号输入有误</div>
          <img
            src="https://static.5t1800.com/s2/2021/06/17/60caa75297d48.png"
            alt
          />
          <input
            @focus="checkInfo"
            @blur="checkMobile"
            type="text"
            placeholder="手机号码"
            v-model="userPhone"
          />
        </div>
        <div class="inputBox flex a_c j_s" style="margin-bottom: 60px">
          <div class="flex a_c">
            <img
              src="https://static.5t1800.com/s2/2021/06/17/60caa762a627a.png"
              alt
            />
            <div v-show="isShowCodeTip" class="textTip">*{{ codeTipMsg }}</div>
            <input
              @focus="checkInfo"
              type="text"
              placeholder="手机验证码"
              v-model="code"
            />
          </div>
          <div class="getVerCode">
            <span @click.stop="getVerCode" v-show="!isGetCode">获取验证码</span>
            <span v-show="isGetCode">{{ timeOut }}s</span>
          </div>
        </div>
        <div class="loginBut" @click="login">登录</div>
        <div class="surprised">注册有惊喜</div>
        <div class="flex j_c a_c">
          <div @click.stop="choseAgreement" class="agreementIcon flex">
            <img
              v-show="isAgreement"
              src="https://static.5t1800.com/s2/2021/06/17/60caab1b628dc.png"
              alt
            />
          </div>
          <div class="agreementText" :class="{ noChose: noAgreementShow }">
            <span
              style="color: #666666"
              :style="{ color: noAgreementShow ? 'red' : '#666666' }"
              >请勾选</span
            >
            <span
              style="cursor: pointer; color: #333333"
              :style="{ color: noAgreementShow ? 'red' : '#333333' }"
            >
              “
              <span style="text-decoration: underline" @click.stop="toAgreement"
                >奈可狮尼协议</span
              >
              ”
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "@/utils/storage";
import api from "@/api/api";
export default {
  name: "",
  data() {
    return {
      choseType: "pass",
      isAgreement: false,
      noAgreementShow: false,
      userPhone: "",
      code: "",
      timeOut: 60,
      isGetCode: false,
      isShowPhoneTip: false,
      isShowCodeTip: false,
      codeTipMsg: "验证码输入有误",
    };
  },
  props: {
    msg: String,
  },
  components: {},
  methods: {
    //勾选协议
    choseAgreement() {
      this.isAgreement = !this.isAgreement;
      this.noAgreementShow = false;
    },
    //跳转至协议
    toAgreement() {
      this.routerTo("specil");
      this.$emit("isShowLogin", "false");
    },
    //选择扫码登录
    choseCode() {
      this.choseType = "code";
      this.refresh();
    },
    //选择账号密码登录
    chosePass() {
      this.choseType = "pass";
      this.refresh();
    },
    //刷新当前数据
    refresh() {
      this.userPhone = "";
      this.userPwd = "";
      this.isAgreement = false;
    },
    //获取验证码
    getVerCode() {
      if (!this.isShowPhoneTip && this.userPhone) {
        this.isGetCode = true;
        this.countDown();
        var parmas = {
          mobile: this.userPhone,
        };
        api
          .$getVerCode(parmas)
          .then((res) => {
            console.log(res.data);
            if (res.data.code == 0) {
              this.$message({
                message: "验证码发送成功！请注意查收",
                type: "success",
              });
            } else {
              this.timeOut = 60;
              this.isGetCode = false;
              this.$message({
                message: "验证码发送失败！请重试",
                type: "error",
              });
            }
          })
          .catch((error) => {
            this.timeOut = 60;
            this.isGetCode = false;
            this.$message({
              message: "验证码发送失败！请重试",
              type: "error",
            });
            console.log(error);
          });
      } else {
        this.isShowPhoneTip = true;
      }
    },
    //登录
    login() {
      if (!this.isShowPhoneTip && this.userPhone && this.code) {
        if (this.isAgreement) {
          var params = {
            mobile: this.userPhone,
            code: this.code,
          };
          api
            .$userLog(params)
            .then((res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                var timeNow = new Date().getTime();
                var userInfo = {
                  token: res.data.data.token,
                  uid: res.data.data.uid,
                  time: parseInt(timeNow / 1000),
                };
                console.log("用户登录存储信息=", userInfo);
                storage.set("userInfo", userInfo, true);
                this.$message({
                  message: "登录成功",
                  type: "success",
                });
                this.$emit("isShowLogin", "false");
              } else {
                this.codeTipMsg = res.data.msg;
                this.isShowCodeTip = true;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.noAgreementShow = true;
        }
      } else if (this.userPhone) {
        this.isShowCodeTip = true;
      } else {
        this.isShowPhoneTip = true;
        this.isShowCodeTip = true;
      }
    },
    //定时器倒计时
    countDown() {
      var time = setInterval(() => {
        if (--this.timeOut == 0) {
          clearInterval(time);
          this.isGetCode = false;
          this.timeOut = 60;
        }
      }, 1000);
    },
    //输入框获取焦点
    checkInfo() {
      this.isShowPhoneTip = false;
      this.isShowCodeTip = false;
      this.codeTipMsg = "验证码输入有误";
    },
    //手机号验证
    checkMobile() {
      var mobile = this.userPhone;
      if (!/^1[3456789]\d{9}$/.test(mobile)) {
        this.isShowPhoneTip = true;
      }
    },
    //关闭登录窗口
    closeLogin() {
      console.log(1);
      this.$emit("isShowLogin", "false");
    },
    //阻止冒泡
    stopOn() {
      console.log(111);
    },
    //二级路由跳转
    routerTo(value) {
      this.$router.push({ name: value });
    },
  },
};
</script>



<style scoped>
.login {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 290px;
  z-index: 999;
}
.loginBox {
  width: 370px;
  height: 440px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #dcdcdc;
}
.loginBox_header {
  padding: 13px 0 3px 0;
  border-bottom: 1px solid #cdcdcd;
  position: relative;
}
/* .loginBox_header .abs1 {
  position: absolute;
  bottom: -1px;
  left: 57px;
  width: 70px;
  height: 1px;
  background: #333333;
}
.loginBox_header .abs2 {
  position: absolute;
  bottom: -1px;
  left: 243px;
  width: 70px;
  height: 1px;
  background: #333333;
} */
.loginBox_header_box {
  height: 29px;
}
.loginBox_header_box .onBox {
  width: 370px;
  height: 29px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 29px;
  text-align: center;
}
.loginBox_header_box .on {
  color: #333333;
}
.loginBox_header_box .icon {
  width: 1px;
  height: 29px;
  background: #cdcdcd;
}
.loginQr {
  width: 126px;
  height: 126px;
  display: block;
  margin: 79px auto 0 auto;
}
.loginUseText {
  margin-top: 15px;
}

.loginUseText > img {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
.loginUseText > span {
  height: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.surprised {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.inputBox {
  width: 310px;
  height: 38px;
  border: 1px solid #cdcdcd;
  margin: 30px auto;
  padding-left: 8px;
  box-sizing: border-box;
  position: relative;
}
.inputBox .textTip {
  color: red;
  position: absolute;
  font-size: 12px;
  bottom: -20px;
  left: 28px;
}
.inputBox img {
  width: 20px;
  height: 20px;
}
.inputBox input {
  outline: none;
  border: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.getVerCode {
  width: 100px;
  height: 36px;
  background: #eeeeee;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}
.loginBut {
  width: 310px;
  height: 38px;
  margin: 0 auto;
  background: #000000;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
}
.agreementIcon {
  width: 11px;
  height: 11px;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
}
.agreementIcon img {
  width: 10px;
  height: 10px;
}

.agreementText {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-left: 7px;
}
.noChose {
  color: red;
  border-bottom: 1px solid red;
}
.login /deep/.el-message--success .el-message__content::before {
  color: #000000;
}
.login /deep/.login .el-message--success {
  background-color: rgba(0, 0, 0, 0.3);
  border-color: #ccc;
}
.login /deep/.login .el-message .el-icon-success {
  color: #000000;
}
</style>
