<template>
  <div class="settleBox">
    <Login @isShowLogin="isShowLogin" v-show="showUserLogin"></Login>
    <div class="receive">
      <div class="headerBox">收货信息</div>
      <div v-show="!isUserLoged" @click="userLogin" class="handOn">请点击登录!</div>
      <div v-show="isUserLoged" class="info">
        <div class="flex j_s a_c">
          <div></div>
          <div @click="choseAdd" class="choseAddIcon">选择收货地址</div>
        </div>
        <div class="inputBox">
          <div>
            <input
              style="width:240px;"
              type="text"
              v-model="addressInfo.contact"
              placeholder="请输入收货人姓名"
              name
              id
            />
            <input
              style="width:364px;margin-left:20px;"
              type="text"
              v-model="addressInfo.mobile"
              placeholder="请输入联系方式"
              name
              id
            />
          </div>
          <div class="input_address">
            <el-select @change="choseProvince" v-model="provinceName" placeholder="请选择">
              <el-option
                v-for="item in provinceList"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <span></span>
            <el-select @change="choseCity" v-model="cityName" placeholder="请选择">
              <el-option
                v-for="item in cityList"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <span></span>
            <el-select @change="choseArea" v-model="areaName" placeholder="请选择">
              <el-option
                v-for="item in areaList"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div>
            <input
              style="width:760px;"
              type="text"
              v-model="addressInfo.address"
              placeholder="请输入详细地址信息"
              name
              id
            />
          </div>
          <div>
            <textarea rows="2" resize="none" placeholder="请输入订单备注信息" v-model="remark"></textarea>
          </div>
        </div>
      </div>
      <div class="headerBox">请立即支付</div>
      <div class="payIcon flex a_c">
        <div class="flex a_c" style="padding:0 110px 0 32px;">
          <img
            style="width:60px;height:44px;"
            src="https://static.5t1800.com/s2/2021/06/22/60d153a5c52a3.png"
            alt
          />
          <span>微信支付</span>
        </div>
        <div class="flex a_c">
          <img
            style="width:77px;height:38px;"
            src="https://static.5t1800.com/s2/2021/06/22/60d153a8eb4db.png"
            alt
          />
          <span>支付宝支付</span>
        </div>
      </div>
      <div class="payIText">*您可以选择手机或ipad移动客户端支付宝、微信扫码功能，扫描二维码付款</div>
      <div style="height:86px;padding-right:10px" class="flex a_c j_r">
        <div class="buyBt" @click="payImmediately" v-loading="loading">立即支付</div>
      </div>
    </div>
    <div class="shopBuy">
      <div class="headerBox">订单详情</div>
      <div class="shopBuy_header flex a_c j_r">
        <span style="margin-right:10px;">商品包邮</span>
        <span>总额（不含运费）:</span>
        <span style="font-size: 18px;color:#FF2742;">￥{{shopPriceAll | keepTwoNum}}</span>
      </div>
      <div class="shopBuy_shop">
        <div class="shopBuy_shop_box flex" v-for="(item,i) in shopBuyList" :key="i">
          <img width="200px" height="200px" :src="item.pic" alt />
          <div class="text">
            <div>{{item.title}}</div>
            <div>{{item.title}}</div>
            <div>{{item.skuTitle}}</div>
          </div>
          <div>￥{{item.price}}</div>
          <div>{{item.number}}件</div>
          <div>￥{{Number(item.price) * Number(item.number) | keepTwoNum}}</div>
        </div>
      </div>
    </div>
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      :show-close="true"
      :size="500"
      v-show="isUserLoged"
    >
      <div v-show="isUserLoged">
        <div class="fix_header">选择地址</div>
        <div class="fix_ul" v-loading="addListLoading" @scroll="scrollEvent">
          <div
            @click="choseFixAddress(i)"
            class="fix_ul_box flex a_c"
            v-for="(item,i) in addList"
            :key="i"
          >
            <div class="fix_ul_icon">
              <img
                v-show="item.isdefault == 1"
                src="https://static.5t1800.com/s2/2021/06/22/60d183b23c112.png"
                alt
              />
              <img
                v-show="item.isdefault == 0"
                src="https://static.5t1800.com/s2/2021/06/22/60d1836288fb0.png"
                alt
              />
            </div>
            <div style="flex:1;">
              <div class="flex j_s" style="margin-bottom:10px;">
                <div>
                  <span>{{item.contact}}</span>
                  <span style="color:#999999;font-size:18px;margin-left:10px;">{{item.mobile}}</span>
                </div>
                <div>
                  <span v-show="item.isdefault == 1" class="fix_ul_default">默认</span>
                </div>
              </div>
              <div>{{item.province_name}} {{item.city_name}} {{item.area_name}} {{item.address}}</div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <div class="pay_img_class flex a_c j_c" v-show="isPayResult">
      <div>
        <img :src="pay_info.qr_base64" alt />
        <div>可选择微信或者支付宝扫码进行支付！</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import storage from "@/utils/storage";
import Login from "@/components/login.vue";
export default {
  data() {
    return {
      radio: 1,
      drawer: false,
      shopBuyList: [],
      addList: [],
      provinceName: "",
      cityName: "",
      areaName: "",
      provinceId: "",
      cityId: "",
      areaId: "",
      provinceList: [],
      cityList: [],
      areaList: [],
      addressInfo: {
        contact: "",
        mobile: "",
        province: "",
        city: "",
        area: "",
        address: "",
        isdefault: "",
        id: ""
      },
      remark: "",
      showUserLogin: false,
      isUserLoged: false,
      addressNum: 1,
      addressAdd: true,
      goods_params: [],
      buy_type: 1,
      loading: false,
      pay_info: {},
      isPayResult: false,
      addListLoading: false
    };
  },
  created() {
    this.fixType();
    this.getUserInfo();
    this.getProvince();
    this.getOrderList();
  },
  mounted() {},
  computed: {
    //订单总价
    shopPriceAll: function() {
      var shopPriceAll = 0;
      for (var i = 0; i < this.shopBuyList.length; i++) {
        shopPriceAll +=
          Number(this.shopBuyList[i].price) *
          Number(this.shopBuyList[i].number);
      }
      return Number(shopPriceAll);
    }
  },
  components: {
    Login
  },
  methods: {
    //立即支付
    payImmediately() {
      if (
        !this.addressInfo.contact ||
        !this.addressInfo.mobile ||
        !this.addressInfo.province ||
        !this.addressInfo.city ||
        !this.addressInfo.area ||
        !this.addressInfo.address
      ) {
        this.$message({
          message: "请完善收货信息！",
          type: "error"
        });
      } else if (!this.loading) {
        this.loading = true;
        var goods_params = this.goods_params;
        var buy_type = this.buy_type;
        var remark = this.remark;
        //换取地址id
        api
          .$addRessChange(this.addressInfo)
          .then(res => {
            console.log(res.data.data.address_id);
            var params = {
              goods_params: JSON.stringify(goods_params),
              address_id: res.data.data.address_id,
              buy_type: buy_type,
              source: "pc",
              remark: remark
            };
            //创建订单
            api
              .$createOrder(params)
              .then(res => {
                console.log(res.data);
                if (res.data.code == 0) {
                  //换取支付二维码
                  api
                    .$js_pay({
                      order_id: res.data.data.order_id
                    })
                    .then(res => {
                      console.log(res.data);
                      if (res.data.code == 0) {
                        this.pay_info = res.data.data;
                        this.isPayResult = true;
                        this.getPayResult(res.data.data.pay_id);
                      }
                      this.loading = false;
                    })
                    .catch(error => {
                      console.log(error);
                    });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error"
                  });
                  this.loading = false;
                }
              })
              .catch(error => {
                console.log(error);
              });
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    //获取结算信息
    getOrderList() {
      var list = storage.get("settlementInfo", true);
      this.shopBuyList = list.goods_info;
      this.goods_params = list.goods_params;
      this.buy_type = list.buy_type;
      console.log(this.shopBuyList);
    },
    //用户登录
    userLogin() {
      console.log(1);
      this.showUserLogin = true;
    },
    //获取用户登录状态
    getUserInfo() {
       var userInfo = storage.get("userInfo", true);
      if (userInfo) {
        var timeNow = new Date().getTime();
        var timeEnd = userInfo.time;
        var timeFall = parseInt(timeNow / 1000) - timeEnd;
        console.log("时间差=", timeFall, "(秒)");
        if (timeFall < 1296000) {
          this.isUserLoged = true;
        }
      }
    },
    //获取子组件登录状态
    isShowLogin(value) {
      console.log(value);
      this.showUserLogin = value;
      this.getUserInfo();
      window.location.reload();
    },
    //滚动条触发事件
    scrollEvent(e) {
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight ==
        e.srcElement.scrollHeight
      ) {
        if (this.addressAdd) {
          this.addressNum++;
          this.getUserAddList(this.addressNum);
        }
      }
    },
    //选择地址
    choseFixAddress(i) {
      console.log(i);
      this.addressInfo.contact = this.addList[i].contact;
      this.addressInfo.mobile = this.addList[i].mobile;
      this.addressInfo.province = this.addList[i].province;
      this.addressInfo.city = this.addList[i].city;
      this.addressInfo.area = this.addList[i].area;
      this.addressInfo.address = this.addList[i].address;
      this.addressInfo.id = this.addList[i].id;
      this.addressInfo.isdefault = this.addList[i].isdefault;
      this.provinceName = this.addList[i].province_name;
      this.cityName = this.addList[i].city_name;
      this.areaName = this.addList[i].area_name;
      this.drawer = false;
      this.cityList = [];
      this.areaList = [];
    },
    //选择地址列表
    choseAdd() {
      this.addList = [];
      this.getUserAddList(this.addressNum);
      this.drawer = true;
    },
    //获取用户地址列表
    getUserAddList(value) {
      this.addListLoading = true;
      var params = {
        page: value
      };
      api
        .$getAddList(params)
        .then(res => {
          console.log(res.data);
          var addList = this.addList;
          this.addList = addList.concat(res.data.data.list);
          if (res.data.data.list.length == 0) {
            this.addressAdd = false;
          }
          this.addListLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.addListLoading = false;
        });
    },
    //选择省份
    choseProvince(value) {
      this.getCity(value);
      this.addressInfo.province = value;
      this.cityName = "";
      this.areaName = "";
      this.cityList = [];
      this.areaList = [];
    },
    //选择市
    choseCity(value) {
      this.getArea(value);
      this.addressInfo.city = value;
      this.areaName = "";
      this.areaList = [];
    },
    //选择区
    choseArea(value) {
      this.addressInfo.area = value;

      console.log(this.addressInfo);
    },
    //获取省列表
    getProvince() {
      api
        .$getChina()
        .then(res => {
          console.log(res.data);
          this.provinceList = res.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //获取市列表
    getCity(value) {
      var params = {
        province_id: value
      };
      api
        .$getChina(params)
        .then(res => {
          console.log(res.data);
          this.cityList = res.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //获取区列表
    getArea(value) {
      var params = {
        city_id: value
      };
      api
        .$getChina(params)
        .then(res => {
          console.log(res.data);
          this.areaList = res.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //固定type栏状态
    fixType() {
      this.$store.commit("saveTypeIndex", 9);
    },
    //查询支付结果
    getPayResult(value) {
      var params = {
        pay_id: value
      };
      var payResult = setInterval(() => {
        api
          .$getPayResult(params)
          .then(res => {
            console.log(res.data);
            if (res.data.data.status != 0) {
              clearInterval(payResult);
              this.toPersonal();
            }
          })
          .catch(error => {
            console.log(error);
          });
      }, 3000);
    },
    //跳转至个人中心页面
    toPersonal() {
      this.$store.commit("savePersonalIndex", 0);
      this.routerTo("personal");
    },
    //二级路由跳转
    routerTo(value) {
      this.$router.push({ name: value });
    }
  }
};
</script>

<style scoped>
.settleBox {
  width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
}
.receive {
  border: 1px solid #999999;
}
.headerBox {
  height: 80px;
  background: #dcdcdc;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 80px;
  padding-left: 10px;
}
.info {
  padding: 10px;
}
.choseAddIcon {
  width: 106px;
  height: 27px;
  background: #000000;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
}
.inputBox input,
textarea {
  height: 46px;
  border: 1px solid #dcdcdc;
  outline: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-indent: 1em;
  margin: 10px 0;
}
.inputBox textarea {
  width: 760px;
  height: 135px;
  resize: none;
  overflow: hidden;
  padding: 10px 0;
}
.inputBox textarea::placeholder {
  color: #ccc;
}
.inputBox input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ccc;
}
.inputBox input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ccc;
}
.inputBox input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
}
.inputBox input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ccc;
}
.input_address span {
  width: 20px;
  display: inline-block;
}
/deep/.inputBox .el-input__inner {
  width: 246px;
  height: 50px;
  border-radius: 0;
  border: 1px solid #dcdcdc;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
/deep/.el-select-dropdown__item.selected {
  color: #000000;
  font-weight: 700;
}
/deep/.inputBox .el-select .el-input.is-focus .el-input__inner {
  border-color: #dcdcdc;
}
/deep/.info .el-radio__input.is-checked .el-radio__inner {
  border-color: #000000;
  background: #000000;
}
/deep/.info .el-radio__input.is-checked + .el-radio__label {
  color: #000000;
}
/deep/.info .el-radio__inner:hover {
  border-color: #000000;
}
.payIcon {
  height: 120px;
}
.payIcon div {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.payIText {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding: 0 0 20px 32px;
  border-bottom: 1px solid #dcdcdc;
}

.shopBuy {
  border: 1px solid #999999;
  margin-top: 40px;
  margin-bottom: 200px;
}

.buyBt {
  width: 106px;
  height: 46px;
  background: #000000;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 46px;
  cursor: pointer;
}
.shopBuy_header {
  height: 54px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding-right: 20px;
  border-bottom: 1px solid #dcdcdc;
}
.shopBuy_shop {
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}
.shopBuy_shop_box {
  height: 200px;
  margin-bottom: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.shopBuy_shop_box > div {
  padding-top: 20px;
  min-width: 150px;
}
.shopBuy_shop_box .text {
  padding: 20px;
  min-width: 200px;
}
.fix_header {
  height: 80px;
  background: #dcdcdc;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
  line-height: 80px;
}
.fix_ul {
  padding: 40px;
  box-sizing: border-box;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  height: calc(100vh - 80px);
  overflow: auto;
}
.fix_ul_icon {
  width: 20px;
  height: 24px;
  margin-right: 14px;
}
.fix_ul_icon img {
  width: 20px;
  height: 24px;
}
.fix_ul_box {
  padding: 20px 0;
  border-bottom: 1px solid #dcdcdc;
  cursor: pointer;
}
.fix_ul_box:hover {
  background: rgba(236, 236, 236, 0.4);
}

.fix_ul_default {
  display: inline-block;
  width: 32px;
  height: 16px;
  background: #edbcc2;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: red;
  line-height: 16px;
  text-align: center;
}
.handOn {
  cursor: pointer;
  padding: 20px;
  font-size: 24px;
  text-decoration: underline;
}
.pay_img_class {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999;
}
.pay_img_class img {
  width: 300px;
  height: 300px;
}
.pay_img_class > div {
  background: white;
  padding: 20px;
  text-align: center;
}
</style>

